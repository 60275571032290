<template>
  <div>
    <div class="search">
      <el-row>
        <el-col :offset="21" :span="1">
          <div class="search_input">
            <el-button
              class="btn"
              icon="el-icon-plus"
              @click="handleAdd"
              type="primary"
              >新增</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <MoTable
      :cols="cols"
      :tableData="tableData"
      @handleEventButton="handleEventButton"
      :operation="operation"
      :page="page"
    />
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="25%"
      :before-close="handleClose"
    >
      <el-input v-model="form.name" placeholder="请输入公司名称"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MoTable from "../../../components/MoTable/MoTable";
export default {
  name: "Company",
  components: {
    MoTable,
  },
  data() {
    return {
      cols: [
        { type: "index", label: "序号" },
        { label: "公司名称", prop: "name" },
      ],
      tableData: [],
      operation: {
        edit: true,
        del: true,
      },
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      dialogVisible: false,

      form: {
        name: "",
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      const res = await this.$get("/company/getList", this.page);
      console.log(res);
      if (res.flag) {
        this.tableData = res.data.rows;
        this.page.total = res.data.total;
      }
    },
    handleAdd() {
      this.dialogVisible = true;
    },
    handleEventButton(type, row) {
      console.log(type);
      if (type == "current") {
        this.page.pageNum = row;
        this.getList();
      } else if (type == "edit") {
        // this.handelEdit(row)
        this.form = Object.assign({}, this.form, row)
        this.dialogVisible = true;
      }else {
        this.$confirm("确认删除？")
          .then((_) => {
            this.handleDel(row.id)
            done();
          })
          .catch((_) => {});
        
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.form.name = "";
          this.dialogVisible = false;
          done();
        })
        .catch((_) => {});
    },
    async onSubmit() {
      if (this.form.name) {
        if (this.form.id) {
          const res = await this.$put("/company/update", this.form);
          if (res.flag) {
            this.form.name = "";
            this.dialogVisible = false;
            this.$message.success(res.message);
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        } else {
          const res = await this.$post("/company/addCompany", this.form);
          if (res.flag) {
            this.form.name = "";
            this.dialogVisible = false;
            this.$message.success(res.message);
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        }
      } else {
        this.$message.error("请输入公司名称");
      }
    },
    async handleDel(id) {
      const res = await this.$del(`/company/del/${id}`);
      if(res.flag){
        this.$message.success(res.message);
        this.getList();
      }else {
        this.$message.error(res.message);
      }
    }
  },
};
</script>

<style lang="less" scoped>
.search {
  width: 100%;
  height: 80px;
  background: #ffffff;
  border-radius: 16px;
  margin-top: 16px;
  .search_input {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    .btn {
      width: 110px;
      height: 40px;
    }
  }
}
</style>